import React from 'react';
import {
    HorizontalFlex,
    PadContainer,
    Title,
    VerticalFlex,
} from 'smartsuite-ui';

import systems from './systems.json';
import ButtonPanel from './ButtonPanel';

import BrockLogo from './images/BrockSolutions.svg';
import './App.css';

function renderSystems(systems) {
    return Object.keys(systems).map((system) => (
        <ButtonPanel key={system} system={systems[system]} />
    ));
}

function App() {
    const testSystems = {};
    const regularSystems = {};

    Object.keys(systems).forEach((system) => {
        if (systems[system].isTestSystem) {
            testSystems[system] = systems[system];
        } else {
            regularSystems[system] = systems[system];
        }
    });

    function renderTestSystems() {
        if (Object.keys(testSystems).length > 0) {
            return (
                <>
                    <PadContainer />
                    <Title cssClassModifiers={['size-medium']}>
                        Test Systems
                    </Title>
                    <HorizontalFlex
                        cssClassModifiers={['flex-wrap', 'justify-center']}
                    >
                        {renderSystems(testSystems)}
                    </HorizontalFlex>
                </>
            );
        }
    }

    return (
        <div className="container">
            <VerticalFlex cssClassModifiers={['align-center']}>
                <span className="app-title">Welcome to SmartSuite</span>
                <Title>Please Pick Your System</Title>
                <HorizontalFlex
                    cssClassModifiers={['flex-wrap', 'justify-center']}
                >
                    {renderSystems(regularSystems)}
                </HorizontalFlex>
                {renderTestSystems()}
                <PadContainer />
                <HorizontalFlex>
                    <img
                        src={BrockLogo}
                        alt="BrockSolutions"
                        className="brock-logo"
                    />
                    <PadContainer>|</PadContainer>
                    <span>© 2003-2025 Brock Solutions Inc.</span>
                </HorizontalFlex>
            </VerticalFlex>
        </div>
    );
}

export default App;
